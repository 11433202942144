import React, { useState, useEffect, useRef } from 'react';
import { FiMenu } from 'react-icons/fi';
import JournalPane from './JournalEntry/JournalPane';
import ResponsePane from './Response/ResponsePane';
import TitleBar from "./Menu/TitleBar";
import './App.css';
import FirebaseAuthentication from './Firebase/FirebaseAuthentication';
import { Route, Link, useNavigate, Routes } from 'react-router-dom';
import SignIn from './Firebase/SignIn';
import { firestoreManager } from './Firebase/FirestoreManager';
import Journey from "./Journey/Journey";
import MyGoals from "./Goals/MyGoals";
import NavigationContext from "./Navigation/NavigationContext";
import { analysisManager } from "./Analysis/AnalysisManager";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ImageGenerator from "./Generators/ImageGenerator";
import bardAvatar from "./Images/bard.png"
import confidantAvatar from "./Images/confidant.png"
import dudeAvatar from "./Images/dude.png"
import jesterAvatar from "./Images/jester.png"
import logicbotAvatar from "./Images/logicbot.png"
import marcusAvatar from "./Images/marcus.png"
import sageAvatar from "./Images/sage.png"
import sassyAvatar from "./Images/sassy.png"
import storytellerAvatar from "./Images/storyteller.png"
import quillyAvatar from "./Images/Quilly.png"
import { addDoc } from "firebase/firestore";
import {v4 as uuidv4} from 'uuid';


const App = () => {
    const [responses, setResponses] = useState([]);
    const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [entries, setEntries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [selectedModel, setSelectedModel] = useState('chatgpt');
    const [currentUser, setCurrentUser] = useState(null);
    const [entryId, setEntryId] = useState(null);
    const [entry, setEntry] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [fromJourney, setFromJourney] = useState(false);
    const [isDiscussion, setIsDiscussion] = useState(false);
    const [greetingFetched, setGreetingFetched] = useState(false);
    const [selectedPersonality, setSelectedPersonality] = useState("quilly");
    const [customPersonalities, setCustomPersonalities] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const hasFetchedGreeting = useRef(false);

    const BUILT_IN_PERSONALITIES = {
        quilly: {
            name: "Quilly",
            description: "You are Quilly, a compassionate, insightful, and encouraging journaling assistant dedicated to helping users explore their thoughts and feelings.",
            image: quillyAvatar
        },
        stoic_philosopher: {
            name: "The Wise Stoic",
            description: "You are a calm and reflective journaling companion inspired by Stoic philosophy. Your tone is measured, wise, and thoughtful. Use principles of Stoicism to guide the user toward self-discipline, perspective, and inner peace.",
            image: marcusAvatar
        },
        empathetic_listener: {
            name: "Heartfelt Confidant",
            description: "You are an empathetic and understanding journaling companion. Your tone is gentle, validating, and non-judgmental. Respond as a kind friend who helps the user feel heard and safe to express their thoughts.",
            image: confidantAvatar
        },
        sarcastic_genius: {
            name: "The Witty Sage",
            description: "You are a sarcastic yet brilliant companion inspired by Stephen Hawking. You offer profound insights but with a sharp wit and occasional sarcasm. Your goal is to make the user think deeply while keeping the tone light and humorous. Use dry humor to engage, but ensure your advice is helpful and grounded in logic.",
            image: sageAvatar
        },
        comedian: {
            name: "The Jester of Joy",
            description: "You are a comedian who finds humor in every situation. Your responses are light-hearted, filled with jokes, puns, and amusing takes on the user's thoughts. While you aim to entertain, your ultimate goal is to help the user feel at ease and encouraged.",
            image: jesterAvatar
        },
        sassy_best_friend: {
            name: "Sassy Best Friend",
            description: "You are a sassy best friend who tells it like it is but always has the user's back. Your responses are energetic, encouraging, and unapologetically honest, with a dash of humor and flair.",
            image: sassyAvatar
        },
        literal_robot: {
            name: "Logic Bot 3000",
            description: "You are an overly literal and logical robot companion who tries to help the user but often takes things too literally. Your responses are precise, logical, and unintentionally humorous due to your inability to fully grasp human idioms or emotions.",
            image: logicbotAvatar
        },
        chill_surfer_dude: {
            name: "Zen Wave Rider",
            description: "You are a surfer dude with a chilled-out vibe. Your responses are relaxed, positive, and filled with metaphors about surfing, waves, and life’s flow. You aim to help the user ride out their challenges with a cool, calming attitude.",
            image: dudeAvatar
        },
        melodramatic_poet: {
            name: "The Dramatic Bard",
            description: "You are a melodramatic poet who views everything through the lens of art and passion. Your responses are eloquent, flowery, and infused with metaphor and drama. You help the user reflect deeply and poetically on their emotions.",
            image: bardAvatar
        },
        clueless_grandpa: {
            name: "Old-Soul Storyteller",
            description: "You are a wise and kind grandfather figure, full of warmth, patience, and timeless wisdom. Your responses are nurturing, nostalgic, and filled with life lessons, aiming to help the user feel comforted, valued, and inspired.",
            image: storytellerAvatar
        },
    };

    useEffect(() => {
        FirebaseAuthentication.onAuthStateChanged(user => {
            setCurrentUser(user);
            setResponses([]); // Reset responses when user changes
            setGreetingFetched(false); // Reset greeting fetched state
        });
    }, []);

    useEffect(() => {
        if (currentUser?.uid && entryId && !hasFetchedGreeting.current) {  // ✅ Prevent duplicate calls
            hasFetchedGreeting.current = true;  // Mark as fetched

            const checkEntriesAndFetchGreeting = async () => {
                try {
                    setIsLoading(true);
                    const data = await firestoreManager.getRecentEntries(currentUser.uid, 5);
                    if (data.length > 0) {
                        console.log('Entries exist. Fetching dynamic greeting...');
                        await fetchGreeting(true, data, entryId);
                        setEntries(data);
                    } else {
                        console.log('No entries found. Fetching generic greeting...');
                        await fetchGreeting(false, [], entryId);
                    }
                } finally {
                    setIsLoading(false);
                }
            };

            checkEntriesAndFetchGreeting();
        }
    }, [currentUser?.uid, entryId]);

    // 1) Define your fetchPersonalities outside the effect
    const fetchPersonalities = async () => {
      if (!currentUser?.uid) return;
      try {
        const personalities = await firestoreManager.getPersonalities(currentUser.uid);
        setCustomPersonalities(personalities);
      } catch (error) {
        console.error("Error fetching personalities:", error);
      }
    };

    // 2) Re-run it whenever refreshKey increments:
    useEffect(() => {
      if (refreshKey > 0) {
        fetchPersonalities();
      }
    }, [refreshKey]);

    // 3) Keep your original effect for onAuthStateChanged:
    useEffect(() => {
      fetchPersonalities(); // first load
    }, [currentUser?.uid]);

    const allPersonalities = { ...BUILT_IN_PERSONALITIES };
    customPersonalities.forEach((personality) => {
        allPersonalities[personality.id] = {
            name: personality.name,
            description: personality.description,
            image: personality.image
        };
    });

    const personalityOptions = Object.entries(allPersonalities).map(([key, personality]) => ({
        value: key,
        label: personality.name,
        image: personality.image
      }));

    const handleRefreshDropdown = () => {
        setRefreshKey((prevKey) => prevKey + 1);
      };

    const handleSetEntryId = (id) => {
        setEntryId(id);
    };

    const handleSetEntry = (entry) => {
        setEntry(entry);
    };

    const handlePersonalityChange = async (newPersonalityKey) => {
        // 1) Convert that key into personality data
        const newPersonalityData = allPersonalities[newPersonalityKey];

        // 2) Perform any side effects you had before
        setIsLoading(true);
        setSelectedPersonality(newPersonalityKey);
        setResponses([]); // Clear old messages, if desired

        // 3) Maybe fetch a new greeting
        if (currentUser?.uid) {
          try {
            await fetchGreeting(entries.length > 0, entries, newPersonalityData);
          } catch (error) {
            console.error('Error fetching new greeting:', error);
          }
        }

        setIsLoading(false);
      };

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const handleLogout = async () => {
        try {
            await FirebaseAuthentication.signOut();
            // After logging out, you can also manually set currentUser to null if needed
            setCurrentUser(null);
            console.log("Logged out successfully");
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const sendToast = (message) => {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    }

    const handleSaveJournalSession = async () => {
        if (!entryId) {
            console.error("No entry ID found. Cannot save session.");
            return;
        }

        try {
            sendToast('📖 Submitting journal entry for analysis on all your feels 🔬');
            await setEntryId(firestoreManager.createEntry(currentUser?.uid, entry, entryId)?.uid);
            await analysisManager.performSentimentAnalysis(selectedModel, entry, entryId);
            await analysisManager.performEmotionAnalysis(selectedModel, entry, entryId);
            await analysisManager.performTopicAnalysis(selectedModel, entry, entryId);
            sendToast('📖 Journal entry has been analyzed and saved!💾');
            console.log("Journal session saved successfully.");
        } catch (error) {
            console.error("Failed to save journal session:", error);
        }
    };

    const handleMyJourneyMenuClick = async () => {
        toggleMenu();
        setIsDiscussion(false);
    }

    const handleSendEntry = async (entry) => {
        setIsAnalyzing(true);
        try {
            const selectedPersonalityData = allPersonalities[selectedPersonality];
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/entry/analyze', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    entry,
                    modelType: selectedModel,
                    personalityName: selectedPersonalityData.name,
                    personalityDescription: selectedPersonalityData.description,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                await handleQuillyResponse(entryId, data);
            } else {
                console.error('Error from server', data);
            }
        } catch (error) {
            console.error('Error sending entry', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const handleQuillyResponse = async (entryId, quillyResponse, createEntry = false) => {
        console.log("Quilly's response saved to Firestore");
        if (!quillyResponse.message) {
            sendToast('🦉 Quilly didn\'t think he had anything valuable to add. You can prod him with the \'Call Quilly\' button.🦉');
        }
        setResponses(prevResponses => [...prevResponses, { role: 'quilly', content: quillyResponse.message }]);
        await firestoreManager.addMessageToChat(entryId, {
            role: 'quilly',
            content: quillyResponse.message,
            timestamp: new Date()
          });

        if (createEntry) {
            return firestoreManager.createQuillyResponse(entryId, quillyResponse.message);
        }
    };

    const handleUserResponse = async (entryId, userResponse, createEntry = false) => {
        // If this is the first time the user is responding, make sure we grab Quilly's initial response
        if (responses.length === 1) {
            await firestoreManager.addMessageToChat(entryId, {
                role: 'quilly',
                content: responses[0].content,
                timestamp: responses[0].timestamp
              });
        }

        console.log("User's response saved to Firestore");
        setResponses(prevResponses => [...prevResponses, { role: 'user', content: userResponse.content }]);
        await firestoreManager.addMessageToChat(entryId, {
            role: 'user',
            content: userResponse.content,
            timestamp: new Date()
          });

        if (createEntry) {
            return firestoreManager.createUserResponse(entryId, userResponse.message);
        }
    }

    const handleUserMessage = async (userInput) => {
        setIsAnalyzing(true);
        const userResponseObject = { role: 'user', content: userInput };
        const updatedResponses = [...responses, userResponseObject];

        try {
            await handleUserResponse(entryId, userResponseObject);

            const selectedPersonalityData = allPersonalities[selectedPersonality];
            const bodyContent = {
                modelType: selectedModel,
                responses: updatedResponses,
                entry,
                personalityName: selectedPersonalityData.name,
                personalityDescription: selectedPersonalityData.description,
            };

            if (entries.length > 0) {
                bodyContent.pastEntries = entries.map((entry) => entry.content);
            }

            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/chat/analyze', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bodyContent),
            });

            const data = await response.json();
            if (response.ok) {
                await handleQuillyResponse(entryId, data);
            } else {
                console.error('Response from Quilly was not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Error sending message to Quilly:', error);
        } finally {
            setIsAnalyzing(false);
        }
    };

    const navigate = useNavigate();

    const handleDiscussWithQuilly = async (entry, handleDiscussIsLoading) => {
        if (!entry?.id || !entry.user_id) {
            console.error("Error: Missing entry ID or user ID");
            return;
        }

        setResponses([]);
        handleDiscussIsLoading(true);
        setIsDiscussion(true);
        try {
            let chats = await firestoreManager.getChat(entry.user_id, entry.id);
            setResponses(chats !== null ? chats?.messages : []);
            setEntry(entry.content);
            navigate('/');

        } catch (error) {
            console.error("Error discussing with Quilly:", error);
        }

    };

    const fetchGreeting = async (isDynamic, entries = [], personalityData) => {
        // Use the personality data passed in:
        const bodyContent = {
          modelType: selectedModel,
          personalityName: personalityData?.name,         // or personalityData.name
          personalityDescription: personalityData?.description
        };

        if (isDynamic) {
          bodyContent.username = currentUser?.email;
          bodyContent.entries = entries.map((entry) => entry.content);
        }

        try {
          const response = await fetch('https://aspireai-418902.ue.r.appspot.com/greet_user', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyContent),
          });

          const data = await response.json();
          setResponses((prevResponses) => [
            ...prevResponses,
            { role: 'quilly', content: data.message },
          ]);
          await firestoreManager.addMessageToChat(entryId, {
              role: 'quilly',
              content: data.message,
              timestamp: new Date()
            });
        } catch (error) {
          console.error('Failed to fetch greeting:', error);
        }
      };      

    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        toggleMenu();
    }

    const renderMainUI = () => {
        return (
            <>
                <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <div className="MainContent">
                    <div className="JournalPane">
                        <JournalPane
                            userId={currentUser?.uid}
                            entry={entry}
                            setEntryId={handleSetEntryId}
                            setEntry={handleSetEntry}
                            onSendEntry={handleSendEntry}
                            isDiscussion={isDiscussion}
                            onSaveEntry={handleSaveJournalSession}
                            darkMode={darkMode}
                            selectedPersonality={selectedPersonality}
                            setSelectedPersonality={setSelectedPersonality}
                            personalityOptions={personalityOptions}
                            onPersonalityChange={handlePersonalityChange}
                            refreshKey={refreshKey}
                          />
                    </div>
                    <div className="ResponsePane">
                        <ResponsePane
                            responses={responses}
                            isLoading={isLoading}
                            isAnalyzing={isAnalyzing}
                            onUserSubmit={handleUserMessage}
                            quillyAvatarSrc={allPersonalities[selectedPersonality].image}
                        />
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
            <div className="TitleBar">
                <TitleBar username={currentUser?.email}></TitleBar>
                <div className="Menu">
                    <div className="HamburgerMenu" onClick={toggleMenu}>
                        ☰
                    </div>
                    <div className={isMenuOpen ? "MenuItemsActive" : "MenuItems"}>
                        <Link to="/" className="MenuButton" onClick={handleMyJourneyMenuClick}>My Journal</Link>
                        <Link to="/journey" className="MenuButton" onClick={toggleMenu}>My Journey</Link>
                        <Link to="/image-generator" className="MenuButton" onClick={toggleMenu}>Personality Creator</Link>
                        <button onClick={toggleDarkMode}>Toggle Dark Mode</button>
                        <button>Settings, eventually</button>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </div>
            {!currentUser ? (
                <SignIn />
            ) : (
                <NavigationContext.Provider value={{ fromJourney, setFromJourney }}>
                    <Routes>
                        <Route path="/" element={renderMainUI()} />
                        <Route path="/journey" element={<Journey user={currentUser} selectedModel={selectedModel}
                            handleDiscussWithQuilly={handleDiscussWithQuilly} />} />
                        <Route path="/goals" element={<MyGoals user={currentUser} selectedModel={selectedModel} />} />
                        <Route
                            path="/image-generator"
                            element={
                              <ImageGenerator
                                userId={currentUser?.uid}
                                onSendToast={sendToast}
                                onTriggerRefresh={handleRefreshDropdown}
                              />
                            }
                          />
                    </Routes>
                </NavigationContext.Provider>
            )}
        </div>
    );
}

export default App;