import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { firestoreManager } from "../Firebase/FirestoreManager";
import { isValid } from "date-fns";
import PulseLoader from "react-spinners/PulseLoader";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EntryAnalysisPieChart from "../Charts/EntryAnalysisPieChart";
import EntryAnalysisLineChart from "../Charts/EntryAnalysisLineChart";
import EntryAnalysisBarChart from "../Charts/EntryAnalysisBarChart";
import WordCloud from "../Charts/WordCloud";
import SummaryAndPlan from "./Trends/SummaryandPlan";
import "./Journey.css";

const Journey = ({ user, selectedModel, handleDiscussWithQuilly }) => {
    const [entries, setEntries] = useState([]);
    const [activeTab, setActiveTab] = useState("entries");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [discussIsLoading, setDiscussIsLoading] = useState(false);
    const [discussingEntryId, setDiscussingEntryId] = useState(null);

    useEffect(() => {
        const loadEntries = async () => {
            if (user?.uid) {
                const data = await firestoreManager.getUserEntries(user?.uid);
                const sortedData = data.sort((a, b) => b.created_at - a.created_at);
                setEntries(sortedData);
            }
        };
        loadEntries();
    }, [user?.uid]);

    // Get journal entries for a selected date
    const getEntriesByDate = (date) => {
        return entries.filter((entry) => {
            const entryDate = entry.created_at.toDate().toLocaleDateString();
            return entryDate === date.toLocaleDateString();
        });
    };

    // Aggregate data for charts
    const sentimentData = entries
        .map(entry => entry.sentiment ? entry.sentiment.toLowerCase() : "unknown"); // Fallback to "unknown" if sentiment is missing

    const sentimentCounts = sentimentData.reduce((acc, entry) => {
        acc[entry] = (acc[entry] || 0) + 1;
        return acc;
    }, {});

    const sentimentDataForChart = Object.entries(sentimentCounts).map(([sentiment, count]) => ({
        name: sentiment,
        value: count,
    }));

    const emotionCounts = entries.reduce((acc, entry) => {
        if (Array.isArray(entry.emotions)) {
            entry.emotions.forEach(emotion => {
                const normalizedEmotion = emotion.trim().toLowerCase();
                acc[normalizedEmotion] = (acc[normalizedEmotion] || 0) + 1;
            });
        }
        return acc;
    }, {});

    const emotionDataForChart = Object.entries(emotionCounts).map(([emotion, count]) => ({
        name: emotion,
        value: count,
    }));

    const emotionDataForWordCloud = Object.entries(emotionCounts).map(([emotion, count]) => ({
        text: emotion,
        value: count,
    }));

    const validEntries = entries.filter(entry => entry && Object.keys(entry).length > 0);

    return (
        <div className="Journey">
            <div className="TabsContainer">
                <button className={`Tab ${activeTab === "entries" ? "TabActive" : ""}`} onClick={() => setActiveTab("entries")}>
                    Journal Entries
                </button>
                <button className={`Tab ${activeTab === "calendar" ? "TabActive" : ""}`} onClick={() => setActiveTab("calendar")}>
                    Calendar View
                </button>
                <button className={`Tab ${activeTab === "summaries" ? "TabActive" : ""}`} onClick={() => setActiveTab("summaries")}>
                    Summaries
                </button>
                <button className={`Tab ${activeTab === "charts" ? "TabActive" : ""}`} onClick={() => setActiveTab("charts")}>
                    Charts
                </button>
            </div>

            <div className="TabsContent">
                {activeTab === "entries" && (
                    <div className="TabContent">
                        {validEntries.map((entry, index) => (
                            <div className="EntryCard" key={index}>
                                <div className="EntryHeader">{entry.created_at.toDate().toLocaleString()}</div>
                                <div className="EntryContent">{entry.content}</div>
                                <div className="EntryMetaData">
                                    <div>Emotions: {Array.isArray(entry.emotions) ? entry.emotions.join(", ") : "None"}</div>
                                    <div>Topics: {Array.isArray(entry.topics) ? entry.topics.join(", ") : "None"}</div>
                                    <div>Sentiment: {entry.sentiment}</div>
                                </div>
                                {discussingEntryId === entry.id && discussIsLoading ? (
                                    <PulseLoader className="Loader" color="#014047"/>
                                ) : (
                                    <button className="ViewDetailsButton"
                                        onClick={() => {
                                            setDiscussingEntryId(entry.id);
                                            handleDiscussWithQuilly(entry, setDiscussIsLoading);
                                        }}>
                                        Revisit Entry
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {activeTab === "calendar" && (
                    <div className="CalendarContainer">
                        <Calendar
                            onChange={setSelectedDate}
                            value={selectedDate}
                            tileClassName={({ date }) => {
                                return getEntriesByDate(date).length ? "has-entry" : null;
                            }}
                        />
                        <div className="EntryList">
                            <h3>Entries for {selectedDate.toLocaleDateString()}</h3>
                            {getEntriesByDate(selectedDate).length === 0 ? (
                                <p>No entries for this date.</p>
                            ) : (
                                getEntriesByDate(selectedDate).map((entry, index) => (
                                    <div className="EntryCard" key={index}>
                                        <div className="EntryHeader">{entry.created_at.toDate().toLocaleString()}</div>
                                        <div className="EntryContent">{entry.content}</div>
                                        {discussingEntryId === entry.id && discussIsLoading ? (
                                            <PulseLoader className="Loader" color="#014047"/>
                                        ) : (
                                            <button className="ViewDetailsButton"
                                                onClick={() => {
                                                    setDiscussingEntryId(entry.id);
                                                    handleDiscussWithQuilly(entry, setDiscussIsLoading);
                                                }}>
                                                Revisit Entry
                                            </button>
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}

                {activeTab === "summaries" && (
                    <div className="TabContent">
                        <SummaryAndPlan user={user} selectedModel={selectedModel} entries={entries} handleDiscussWithQuilly={handleDiscussWithQuilly}/>
                    </div>
                )}

                {activeTab === "charts" && (
                    <div className="TabContent">
                        <div className="ChartContainer">
                            <EntryAnalysisPieChart sentimentData={sentimentDataForChart} height={400} width={300} />
                            <EntryAnalysisBarChart analysisData={emotionDataForChart} height={400} width={800} />
                            <WordCloud words={emotionDataForWordCloud} width={1200}/>
                            <EntryAnalysisLineChart analysisData={entries} height={400} width={1200} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Journey;
