import ReactWordcloud from 'react-wordcloud';

// Optional custom configuration for the word cloud
const options = {
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000,
  fontSizes: [20, 100],
  colors: ["#4db8ff", "#ffcc66", "#66ff66", "#ff6666", "#c266ff", "#ff99cc", "#ffff66", "#66ffff", "#ff9966", "#99ff99"],
  deterministic: false,
  fontFamily: "impact",
};

const CustomWordCloud = ({ words, width = 600, height = 400 }) => {
  // Ensure the container has a set size
  const containerStyle = {
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div style={containerStyle}>
      <ReactWordcloud words={words} options={options} />
    </div>
  );
};

export default CustomWordCloud;