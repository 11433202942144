import React, { useState, useEffect, useRef } from 'react';
import RiseLoader from "react-spinners/RiseLoader";
import './ResponsePane.css';
import quillyAvatar from '../Images/avatar_quilly.png';
import userAvatar from '../Images/avatar_user_male.png'


const ResponsePane = ({ responses, isLoading, isAnalyzing, onUserSubmit, quillyAvatarSrc }) => {
    const [userInput, setUserInput] = useState('');
    const messagesEndRef = useRef(null);
    const [expandedMessages, setExpandedMessages] = useState(new Set());

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    useEffect(() => {
        // When responses are updated, ensure the latest message is expanded
        setExpandedMessages(new Set(responses.map((_, index) => index)));
        }, [responses]);    

    const toggleMessage = (index) => {
        const newExpandedMessages = new Set(expandedMessages);
        if (newExpandedMessages.has(index)) {
            newExpandedMessages.delete(index);
        } else {
            newExpandedMessages.add(index);
        }
        setExpandedMessages(newExpandedMessages);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
        }, [responses, isLoading, isAnalyzing]);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        onUserSubmit(userInput); // Call the passed function with the current state value
        setUserInput(''); // Clear the input after submitting
    };

    const FormattedText = ({ text }) => {
        const formatText = (inputText) => {
          const lines = inputText.split("\n");
          const formattedElements = [];
          let nestedList = [];
          let isNested = false;

          lines.forEach((line, index) => {
            // Handle bold text formatting (**bold**)
            line = line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

            // Handle italic text formatting (*italic*)
            line = line.replace(/\*(.*?)\*/g, "<em>$1</em>");

            if (/^---+$/.test(line)) {
              // Add a horizontal rule for lines with "---"
              formattedElements.push(<hr key={index} />);
            } else if (/^###\s(.+)/.test(line)) {
              // Handle level-3 headings (###)
              const headingText = line.replace(/^###\s/, "");
              formattedElements.push(<h3 key={index} dangerouslySetInnerHTML={{ __html: headingText }} />);
            } else if (/^\s*-\s/.test(line)) {
              // Handle bullet-point lists
              const cleanText = line.replace(/^\s*-\s/, ""); // Remove "- " prefix

              if (!isNested) {
                isNested = true;
                nestedList = [
                  <li key={index} dangerouslySetInnerHTML={{ __html: cleanText }} />,
                ];
              } else {
                nestedList.push(
                  <li key={index} dangerouslySetInnerHTML={{ __html: cleanText }} />
                );
              }

              if (index === lines.length - 1 || !/^\s*-\s/.test(lines[index + 1])) {
                // Wrap nested list items in a <ul> when the list ends
                isNested = false;
                formattedElements.push(
                  <ul key={`nested-${index}`}>{nestedList}</ul>
                );
              }
            } else if (/^\s{4,}/.test(line)) {
              // Handle indented lines (suggestions or nested content)
              const cleanText = line.trim();
              formattedElements.push(
                <p key={index} className="indented" dangerouslySetInnerHTML={{ __html: cleanText }} />
              );
            } else if (line.trim().startsWith("Welcome back")) {
              // Handle personalized greeting
              formattedElements.push(
                <p key={index} className="greeting" dangerouslySetInnerHTML={{ __html: line }} />
              );
            } else if (line.trim() === "") {
              // Add a line break for empty lines
              formattedElements.push(<br key={index} />);
            } else {
              // Regular text (paragraphs)
              formattedElements.push(
                <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
              );
            }
          });

          return formattedElements;
        };

        return <div className="formatted-text-container">{formatText(text)}</div>;
      };

    // Conditional rendering based on isLoading and isAnalyzing
    return (
        <div className="Responses">
            {/* Render previous responses */}
            {responses
              .filter((response) => response.content) // filter out empty content
              .map((response, index) => (
                <div
                  key={index}
                  className={`ResponseBubble ${
                    response.role === "quilly" ? "QuillyBubble" : "UserBubble"
                  }`}
                  onClick={() => toggleMessage(index)}
                >
                  <img
                    src={response.role === "quilly" ? quillyAvatarSrc : userAvatar}
                    alt={response.role}
                    className={response.role === "quilly" ? "QuillyAvatar" : "UserAvatar"}
                  />
                  <div className="ResponseContent">
                    <div className="ResponseMessage">
                      <FormattedText
                        text={
                          expandedMessages.has(index)
                            ? response.content
                            : `${response.content.substring(0, 100)}...`
                        }
                      />
                    </div>
                    <div className="ExpandCollapseIcon">
                      {expandedMessages.has(index) ? "▲" : "▼"}
                    </div>
                  </div>
                </div>
              ))}
            {/* Automatic scroll to this div when new message is added */}
            <div ref={messagesEndRef}/>
            {/* Render a spinner if awaiting greeting (isLoading) or analysis response (isAnalyzing) */}
            {(isLoading || isAnalyzing) && (
                <div className="ResponseBubble">
                    <img src={quillyAvatarSrc} alt="Quilly" className="QuillyAvatar"/>
                    <div className="ResponseLoader">
                        <RiseLoader color="#e7f5ff" size={10}/>
                    </div>
                </div>
            )}
            <div className="ChatInputContainer">
                <form onSubmit={handleSubmit} className="ChatInputForm">
                    <input
                        type="text"
                        value={userInput}
                        onChange={handleInputChange}
                        placeholder="Chat with Quilly..."
                        className="ChatInput"
                    />
                    <button type="submit" className="ChatSubmitButton">✉️ Send</button>
                </form>
            </div>
        </div>
    );
};

export default ResponsePane;