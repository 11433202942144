import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { firestoreManager } from '../Firebase/FirestoreManager';
import './JournalEntry.css'
import PersonalityDropdown from "../Inputs/PersonalityDropdown";

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const JournalPane = ({ userId, onSendEntry, setEntryId, entry, setEntry, isDiscussion, onSaveEntry, darkMode, personalityOptions, selectedPersonality, setSelectedPersonality, onPersonalityChange, refreshKey }) => {
    // Effect hook to create a new journal entry on component mount
    useEffect(() => {
        const createEntry = async () => {
            if (userId) {
                try {
                    const docRef = uuidv4();
                    console.log("Created entry ID:", docRef);
                    setEntryId(docRef);
                } catch (error) {
                    console.error("Failed to create entry:", error);
                }
            } else {
                console.error("User not logged in");
            }
        };

        createEntry();
        }, [userId]);

    const debouncedUpdateEntry = useCallback(
        debounce(async (entryId, newEntry) => {
            if (entryId) {
                await firestoreManager.updateEntry(entryId, newEntry);
                console.log("Entry updated.");
            }
        }, 10), // 1000ms delay for debouncing
        []
        );

    const handleTextChange = async (event) => {
        const newEntry = event.target.value;
        setEntry(newEntry); // Update the App component's state
    };

    const handleAnalyzeClick = () => {
        // Trigger the analysis of the current journal entry
        onSendEntry(entry);
    };

     return (
    <div className="JournalPane">
      <textarea
        value={entry}
        onChange={handleTextChange}
        placeholder="Hey journal..."
        className="JournalInput"
      />
      {!isDiscussion && (
        <div className="ButtonContainer">
          <button onClick={handleAnalyzeClick} className="AnalyzeButton">
            🦉 Quilly's Thoughts
          </button>
          <button onClick={onSaveEntry} className="AnalyzeButton">
            💾 Save Entry
          </button>

          {!isDiscussion && (
              <div>
                  {/** Our React Select dropdown with avatar support **/}
                  <PersonalityDropdown
                      isDarkMode={darkMode}
                      personalityOptions={personalityOptions}
                      selectedPersonality={selectedPersonality}
                      onPersonalityChange={onPersonalityChange}
                      refreshKey={refreshKey}
                  />
              </div>
          )}
        </div>
      )}
    </div>
  );
}

export default JournalPane;
